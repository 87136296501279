<template>
  <v-text-field
    :prepend-icon="iconSearch"
    :label="label"
    :clearable="clearable"
    dense
    single-line
    hide-details
    v-bind="$attrs"
    v-on="$listeners"
    @keyup.enter="performSearch"
  >
    <template v-slot:append-outer>
      <v-tooltip bottom v-if="visibleSearchButton">
        <template v-slot:activator="{ on }">
          <v-icon
            class="mr-2"
            :disabled="!isUniversalSearch"
            @click="performSearch"
            :color="menuItemColorClass"
            v-on="on"
            >{{ iconReload }}</v-icon
          >
        </template>
        <span>{{ searchTooltip }}</span>
      </v-tooltip>

      <base-tooltip-icon
        v-if="visibleSearchButton && !isUniversalSearch"
        top
        :icon-name="iconWarning"
        :icon-color-class="iconColor"
      >
        {{ disabledUniversalSearchText }}
      </base-tooltip-icon>
    </template>
  </v-text-field>
</template>

<script>
import { iconReload, iconSearch, iconWarning } from "@/design/icon/iconConst";
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { eventNames } from "@/model/common/events/eventConst";
import { colorTheme } from "@/design/colors/mdColors";
export default {
  inheritAttrs: false,
  name: "BaseSearchTextField",
  components: {
    BaseTooltipIcon: () => import("@/components/shared/base/BaseTooltipIcon")
  },
  mixins: [menuItemSettingsMixin],
  props: {
    label: {
      type: String,
      default: "Search"
    },
    clearable: {
      type: Boolean,
      default: true
    },
    isUniversalSearch: {
      type: Boolean,
      default: true
    },
    disabledUniversalSearchText: {
      type: String,
      default: undefined
    },
    visibleSearchButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconWarning: iconWarning,
      iconColor: colorTheme.warning
    };
  },
  computed: {
    iconSearch: () => iconSearch,
    iconReload: () => iconReload,
    searchTooltip() {
      return "Apply Search";
    }
  },
  methods: {
    /**
     * Perform Universal Search
     */
    performSearch() {
      if (this.isUniversalSearch) this.$emit(eventNames.onSearchAction);
    }
  }
};
</script>
